import React from "react"
import './backdrop.css'

const Backdrop = props => {
  let navDrawerClasses = '';
  if (props.navDrawerShow) {
    navDrawerClasses = 'open'
  }
  return (
    <div id="backdrop" className={navDrawerClasses} onClick={props.toggleHandler}></div>
  )
}

export default Backdrop
