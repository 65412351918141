/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

import Backdrop from "../components/backdrop"
import NavDrawer from "../components/nav-drawer"

class StaticLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNavOpen: false
    };
    this.toggleMobileNav = this.toggleMobileNav.bind(this);
  }

  toggleMobileNav() {
    this.setState((prevState) => {
      return {mobileNavOpen: !prevState.mobileNavOpen}
    });
  }

  render() {
    StaticLayout.propTypes = {
      children: PropTypes.node.isRequired,
    }
    return (
      <StaticQuery
      query={graphql`
        query TitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <NavDrawer navDrawerShow={this.state.mobileNavOpen}/>
          <Backdrop navDrawerShow={this.state.mobileNavOpen} toggleHandler={this.toggleMobileNav}/>
          <Header toggleHandler={this.toggleMobileNav} isRelative={true} headerColor={this.props.headerColor} />
          <div>
            <main>{this.props.children}</main>
            <Footer></Footer>
          </div>
        </>
      )}
      />
    )
  }
}

export default StaticLayout
