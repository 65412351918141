import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import "./latest-posts.css"

var dateFormat = require('dateformat');

const LatestPosts = () => {
  const { latest } = useStaticQuery(latestPostsQuery);
  const { edges: latestPosts } = latest;
  let latestPostsData = {};
  let counter = 0;
  latestPosts.map(({ node }) => {
    latestPostsData[counter] = node
    counter += 1
    return node
  })

  function processTypeClasses(type) {
    let displayArticleTypeClasses = "article-type uppercase text-xs font-bold";
    switch (type) {
      case "build":
        displayArticleTypeClasses += " build-type";
        break;
      case "cards":
        displayArticleTypeClasses += " cards-type";
        break;
      case "score":
        displayArticleTypeClasses += " score-type";
        break;
      default:
        break;
    }
    return displayArticleTypeClasses
  }

  return (<div className="more-articles flex flex-wrap overflow-hidden p-8">
              <a href={"/" + latestPostsData[0].fields.blogPath} className="article-item w-full overflow-hidden mb-6 article-item-l md:w-1/3">
                <div className="article-cover" style={{backgroundImage: 'url(' +latestPostsData[0].frontmatter.coverImgUrl  + ')'}}></div>
                <div className="article-description px-4 py-6">
                  <div className={processTypeClasses(latestPostsData[0].frontmatter.type)}><span>{latestPostsData[0].frontmatter.type}</span></div>
                  <div className="font-bold text-xl py-1"><span>{latestPostsData[0].frontmatter.title}</span></div>
                  <div className="article-publish-date text-xs">{dateFormat(latestPostsData[0].frontmatter.date, "mmmm d, yyyy")}</div>
                </div>
              </a>
              <a href={"/" + latestPostsData[1].fields.blogPath} className="article-item w-full overflow-hidden mb-6 md:w-1/3">
                <div className="article-cover" style={{backgroundImage: 'url(' +latestPostsData[1].frontmatter.coverImgUrl  + ')'}}></div>
                <div className="article-description px-4 py-6">
                  <div className={processTypeClasses(latestPostsData[1].frontmatter.type)}><span>{latestPostsData[1].frontmatter.type}</span></div>
                  <div className="font-bold text-xl py-1"><span>{latestPostsData[1].frontmatter.title}</span></div>
                  <div className="article-publish-date text-xs">{dateFormat(latestPostsData[1].frontmatter.date, "mmmm d, yyyy")}</div>
                </div>
              </a>
              <a href={"/" + latestPostsData[2].fields.blogPath} className="article-item w-full overflow-hidden mb-6 article-item-r md:w-1/3">
                <div className="article-cover" style={{backgroundImage: 'url(' + latestPostsData[2].frontmatter.coverImgUrl + ')'}}></div>
                <div className="article-description px-4 py-6">
                  <div  className={processTypeClasses(latestPostsData[2].frontmatter.type)}><span>{latestPostsData[2].frontmatter.type}</span></div>
                  <div className="font-bold text-xl py-1"><span>{latestPostsData[2].frontmatter.title}</span></div>
                  <div className="article-publish-date text-xs">{dateFormat(latestPostsData[2].frontmatter.date, "mmmm d, yyyy")}</div>
                </div>
              </a>
              </div>
  )
}

export default LatestPosts

const latestPostsQuery = graphql`
  query latestPosts {
    latest: allMdx(
      limit: 3
      sort: {fields: [frontmatter___date], order: DESC}
    ) {
      edges {
        node {
          id
          fields {
            blogType
            blogPath
          }
          frontmatter {
            title
            author
            date
            type
            coverImgUrl
          }
        }
      }
    }
  }
`
