import PropTypes from "prop-types"
import React from "react"
import './tailwind.css'
import './header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from "@fortawesome/free-solid-svg-icons";

import logo from '../images/logo.svg'
const Header = ({toggleHandler, scrolled, scrollDown, isRelative, headerColor, isHome}) => {
  let navClasses = "nav px-4 py-3 md:px-8 md:py-4 lg:px-12 lg:py-6";
  let headerClasses = "header"
  let navStyle = {}

  if (isRelative)
  {
    navClasses = "nav px-4 py-3 md:px-8 md:py-4 lg:px-12 lg:py-6 relative-nav";
    headerClasses = "header-relative"
    navStyle = {backgroundColor: headerColor}
  }
  else {
    if (scrolled) {
      if (scrollDown) {
        navClasses = "nav px-4 py-3 md:px-8 md:py-4 lg:px-12 lg:py-6 scrolled scrolling-down";
      }
      else {
        navClasses = "nav px-4 py-3 md:px-8 md:py-4 lg:px-12 lg:py-6 scrolled scrolling-up";
      }
    }
    else {
      navClasses = "nav px-4 py-3 md:px-8 md:py-4 lg:px-12 lg:py-6";
    }
  }

  return (
    <header className={headerClasses}>
      <nav className={navClasses} style={navStyle} role="navigation">
        <div className="absolute bars-icon pt-2 hover:pointer md:hidden" onClick={toggleHandler}><FontAwesomeIcon icon={faBars} className="hover:pointer" size="lg"/></div>
        <a href="/" className="block md:inline-block logo-block">
          <img src={logo} alt="Logo" id="logo" className="h-10 m-auto text-center md:h-12 md:mt-1"/>
        </a>
        <div className="nav-list hidden md:inline-block">
          <ul className="nav-list-inner">
            <li className="nav-item p-4 font-bold text-lg">
              <a className="nav-link top-bar" href="/score">Score</a>
            </li>
            <li className="nav-item p-4 font-bold text-lg">
              <a className="nav-link top-bar" href="/build">Build</a>
            </li>
            <li className="nav-item p-4 font-bold text-lg">
              <a className="nav-link top-bar" href="/cards">Cards</a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
