import React from "react"
import './nav-drawer.css'

const NavDrawer = props => {
  let navDrawerClasses = 'nav-drawer';
  if (props.navDrawerShow) {
    navDrawerClasses = 'nav-drawer open'
  }
  return (
    <div id="sidenav" className={navDrawerClasses}>
      <a className="nav-drawer-item" href="/score">Score</a>
      <a className="nav-drawer-item" href="/build">Build</a>
      <a className="nav-drawer-item" href="/cards">Cards</a>
    </div>
  )
}


export default NavDrawer
