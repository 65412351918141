import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kevin/Documents/GitHub/credit101-react/src/components/blog-post-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We want to make it easier for you to learn vocabulary related to credit score, so here's a glossary of words related to credit score. Note that if you're looking for credit card terms, we have another article for that.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Related: `}<a parentName="p" {...{
          "href": "https://credit101.net/cards/credit-card-glossary"
        }}>{`Credit Card Glossary (50 Terms)`}</a></p>
    </blockquote>
    <h2>{`A`}</h2>
    <div className="px-8" id="average-age-of-accounts"><label><strong>Average Age of Accounts</strong>: The average time your accounts have been opened. This is often an indicator of how long you've been managing credit.</label></div>
    <div className="px-8" id="available-credit"><label><strong>Available Credit</strong>: The remaining amount you are able to lend from loan and credit card issuers. This is calculated by deducting unpaid balances from your credit lines combined. </label></div>
    <h2>{`B`}</h2>
    <div className="px-8" id="bankruptcy"><label><strong>Bankruptcy</strong>: A person under debt may seek to be relieved of their debts through declaring bankruptcy. A U.S. bankruptcy court may then relieve the person of some if not all of their debts. Bankruptcy is a public record and will remain on your credit report for 7-10 years. It will seriously affect your credit score.</label></div>
    <h2>{`C`}</h2>
    <div className="px-8" id="credit-bureau"><label><strong>Credit Bureau</strong>: A credit reporting agency that collects and reports credit information on firms and individuals.</label></div>
    <div className="px-8" id="credit-risk"><label><strong>Credit Risk</strong>: The risk creditors take when they issue loans and credit cards. The less creditworthy a person is, the greater the risk the creditor has to take.</label></div>
    <div className="px-8" id="credit-history"><label><strong>Credit History</strong>: A record of how well a person has managed their credit accounts in the past.</label></div>
    <div className="px-8" id="credit-report"><label><strong>Credit Report</strong>: A report of your credit history.</label></div>
    <div className="px-8" id="credit-score"><label><strong>Credit Score</strong>: The score generated by a statistical model used by lenders to assess credit risk on an individual or organization.</label></div>
    <div className="px-8" id="closed-account"><label><strong>Closed Account</strong>: An account that has been closed. Delinquent accounts will remain on your credit report for up to 7 years. Positive accounts might remain longer.</label></div>
    <div className="px-8" id="collection-account"><label><strong>Collection Account</strong>: An account that has been marked for collection agency for collection. Collection accounts will remain on your credit report for 7 years.</label></div>
    <div className="px-8" id="charged-off"><label><strong>Charged-off</strong>: A way of saying that the debt is unlikely to be collected. Creditors mark an account as charged off if it is seriously delinquent. Accounts that are charged-off will remain on your record for 7 years.</label></div>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block",
        "textAlign": "center"
      }} data-ad-layout="in-article" data-ad-format="fluid" data-ad-client="ca-pub-3524456596508824" data-ad-slot="9281973399">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`D`}</h2>
    <div className="px-8" id="delinquency"><label><strong>Delinquency</strong>: Failure to pay the minimum payment by the due date.</label></div>
    <div className="px-8" id="default"><label><strong>Default</strong>: Failure to pay the minimum payment by the due date for multiple statement periods. Being delinquent for an extended period of time will result in default. </label></div>
    <h2>{`E`}</h2>
    <div className="px-8" id="equifax"><label><strong>Equifax</strong>: One of the top three U.S. credit bureaus.</label></div>
    <div className="px-8" id="experian"><label><strong>Experian</strong>: One of the top three U.S. credit bureaus.</label></div>
    <h2>{`F`}</h2>
    <div className="px-8" id="fico"><label><strong>FICO</strong>: Fair Isaac Corporation, which created the FICO credit score that is used by 90% of top lenders for creditworthy assessments.</label></div>
    <div className="px-8" id="FCRA"><label><strong>FCRA</strong>: Federal law regulating how credit bureaus report your credit information.</label></div>
    <h2>{`L`}</h2>
    <div className="px-8" id="length-of-credit"><label><strong>Length of Credit</strong>: The age of your oldest account, which is used to determine creditworthiness.</label></div>
    <div className="px-8" id="late-payment"><label><strong>Late Payment</strong>: A late payment is a payment made past the due date, which breaches the loan or credit card agreement. Late payment is a delinquent payment.</label></div>
    <h2>{`M`}</h2>
    <div className="px-8" id="missed-payments"><label><strong>Missed Payments</strong>: The number of times you failed to pay the minimum payment on time.</label></div>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block"
      }} data-ad-client="ca-pub-3524456596508824" data-ad-slot="5342728383" data-ad-format="auto" data-full-width-responsive="true">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`H`}</h2>
    <div className="px-8" id="hard-inquiry"><label><strong>Hard Inquiry / Hard Pull</strong>: A request for one's credit history by a creditor. A hard inquiry will result in a new entry on your credit report (regardless of your approval) and might negatively affect your score.</label></div>
    <h2>{`O`}</h2>
    <div className="px-8" id="oldest-account"><label><strong>Oldest Account</strong>: The oldest credit account you have on your credit report. We recommend keeping the account open as having an old, well-maintained account is an indicator of creditworthiness.</label></div>
    <h2>{`P`}</h2>
    <div className="px-8" id="payment-history"><label><strong>Payment History</strong>: The consistency of past on-time payments.</label></div>
    <h2>{`R`}</h2>
    <div className="px-8" id="revolving-account"><label><strong>Revolving Account</strong>: An account that doesn't require the balances be paid in full by each statement period. Most credit card accounts are revolving accounts.</label></div>
    <div className="px-8" id="recent-accounts"><label><strong>Recent Accounts / Recent Credit</strong>: The number of recent accounts opened. Too many accounts opened recently translates to increased credit risk for issuers.</label></div>
    <h2>{`S`}</h2>
    <div className="px-8" id="soft-inquiry"><label><strong>Soft Inquiry / Soft Pull</strong>: A request for one's credit history, usually initiated by credit monitoring services or when you're considered for pre-approval. Soft inquiries do not show up on your credit report and do not harm your credit score.</label></div>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block",
        "textAlign": "center"
      }} data-ad-layout="in-article" data-ad-format="fluid" data-ad-client="ca-pub-3524456596508824" data-ad-slot="9281973399">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`T`}</h2>
    <div className="px-8" id="transunion"><label><strong>TransUnion</strong>: One of the three largest U.S. credit bureaus.</label></div>
    <div className="px-8" id="total-accounts"><label><strong>Total Accounts</strong>: Total number of accounts opened.</label></div>
    <h2>{`U`}</h2>
    <div className="px-8" id="utilization-ratio"><label><strong>Utilization Ratio</strong>: Total balance over total credit limit. Credit scores that take utilization ratio into account usually only look at the utilization of your revolving accounts.</label></div>
    <h2>{`V`}</h2>
    <div className="px-8" id="vantagescore"><label><strong>VantageScore Report</strong>: FICO score's competitor. Usually used as a consumer-facing educational score reporting their credit standing. VantageScore is generally not used by lenders to assess credit risk. </label></div>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      