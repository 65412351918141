import React from "react"

import "./footer.css"

const Footer = () => (
  <div className="footer px-4 py-8 text-xs sm:text-sm md:text-base">
      <div className="text-left px-2 md:px-12 lg:px-32">
        <p>Advertiser Disclosure: Our website depends on your support. To operate this site, we may receive potential compensations from advertisers like Amazon Associates in exchange for displaying related product links. However, opinions expressed on this site only represents the author’s sole perspective, not those of any advertiser.</p>
        <br/>
        <p>Disclaimer: Contents on Credit 101 only represents the site owner's personal experiences and viewpoints. Articles on Credit 101 are of opinion and general nature and should not be relied upon for individual circumstances, nor should they be taken as investment, financial planning, tax, or legal advice. Please seek professional advice before making all credit decisions. For more information, visit our <a className="underline" href="/disclaimer">disclaimer page</a>.</p>
      </div>
      <hr/>
      <div>
        <a href="/privacy">Privacy Policy</a>
        <span className="footer-mid"> | </span>
        <a href="/tos">Terms of Use</a>
        <span className="footer-mid"> | </span>
        <a href="/contact">Contact Us</a>
      </div>
      <div className="copyright">
        <span className="mx-auto block">© 2019 Credit 101. All rights reserved.</span>
      </div>
  </div>
)

export default Footer
