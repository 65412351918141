import React from 'react'
import {Helmet} from "react-helmet";

import StaticLayout from './static-layout'
import SEO from "../components/seo"
import LatestPosts from "../components/blog/latest-posts"
import Disqus from 'gatsby-plugin-disqus'

import './blog-post-layout.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare, faTwitter } from '@fortawesome/free-brands-svg-icons'

var dateFormat = require('dateformat');

const BlogPostLayout = ({ children, pageContext, location }) => {
  const { title, date, type, coverImgUrl, tags, excerpt } = pageContext.frontmatter;
  let articleType = "";
  let articleTypeClasses = "block uppercase font-bold text-sm";
  let articlePublishDate = new Date(date);
  articlePublishDate = dateFormat(articlePublishDate, "mmmm d, yyyy");
  let headerColor = "#355384";
  switch (type) {
    case "build":
      articleType = "Build Credit";
      articleTypeClasses += " build-type";
      headerColor = "#4299e1";
      break;
    case "cards":
      articleType = "Credit Cards";
      articleTypeClasses += " cards-type";
      headerColor = "#6633ff";
      break;
    case "score":
      articleType = "Credit Score";
      articleTypeClasses += " score-type";
      headerColor = "#38a169";
      break;
    case "rebuild":
      articleType = "Credit Rebuild";
      articleTypeClasses += " rebuild-type";
      headerColor = "#355384";
      break;
    default:
      articleType = "Feature";
      break;
  }
  let fbShareLink = "https://www.facebook.com/sharer.php?u=https%3A%2F%2Fcredit101.net" + location.pathname;
  let jsonArticleTitle = title.replace(" ", "+");
  let twitterLink = "https://twitter.com/intent/tweet?url=https%3A%2F%2Fcredit101.net" + location.pathname + "&text=" + jsonArticleTitle +  "+-+Credit+101&hashtags=credit, creditbuilding, creditscore,creditcard"
  let absLink = "https://credit101.net" + location.pathname;
  let linkCopiedIndicatorClasses = "link-copy-indicator";

  let articleUrl = "https://credit101.net" + location.pathname;
  let uniqueID = location.pathname;

  function setClipboard() {
    var tempInput = document.createElement("input");
    tempInput.style = "position: absolute; left: -1000px; top: -1000px";
    tempInput.value = absLink;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    document.getElementById("link-copy-indicator").classList.add('link-indicator-show');
    document.getElementById("second-sharesheet-heading").innerHTML = "Copied to Clipboard";

    setTimeout(
      function() {
        document.getElementById("link-copy-indicator").classList.remove('link-indicator-show');
        document.getElementById("second-sharesheet-heading").innerHTML = "Share this article:";
      }, 1500);
  }
  return (
    <StaticLayout headerColor={headerColor}>
      <SEO title={title + " - Credit 101"} keywords={tags} description={excerpt} />
			<div>
				<div className="content px-0 md:px-20 lg:px-auto xl:px-auto">
		      <section className="px-8">
		        <div className="pt-10">
		          <a href={"/" + type} className={articleTypeClasses}>{articleType}</a>
		          <span className="publish-date block font-medium text-sm">{articlePublishDate}</span>
		        </div>
		        <div>
		          <h1 className="font-bold leading-tight text-3xl py-6">{title}</h1>
		        </div>
            <div className="sharesheet">
              <div className="sharesheet-content">
                <ul className="flex">
                  <li><a href={fbShareLink}><FontAwesomeIcon icon={faFacebookSquare} className="mr-5 sharesheet-icon fb-icon" size="lg"/></a></li>
                  <li><a href={twitterLink}><FontAwesomeIcon icon={faTwitter} className="mr-5 sharesheet-icon twitter-icon" size="lg"/></a></li>
                  <li onClick={setClipboard}><FontAwesomeIcon icon={faLink} className="mr-5 sharesheet-icon link-icon" size="lg"/></li>
                  <li id="link-copy-indicator" className={linkCopiedIndicatorClasses}>Copied to Clipboard</li>
                </ul>
              </div>
            </div>
		      </section>
		      <div className="sep-line px-6 pt-4 pb-10"><div className="sep-line-inner"></div></div>
		      <section className="article">
		        <div className="cover-img pb-6"><img src={coverImgUrl} alt={title}/></div>
		        {children}
		      </section>
          <section className="secondary-sharesheet">
		        <div className="sep-line px-6 py-4 pb-8"><div className="sep-line-inner"></div></div>
              <div className="secondary-sharesheet-content">
                <div className="font-medium text-center secondary-sharesheet-description" id="second-sharesheet-heading">Share this article:</div>
                  <div className="pt-2">
                    <ul className="flex justify-center">
                      <li><a href={fbShareLink}><FontAwesomeIcon icon={faFacebookSquare} className="mx-3 sharesheet-icon fb-icon" size="lg"/></a></li>
                      <li><a href={twitterLink}><FontAwesomeIcon icon={faTwitter} className="mx-3 sharesheet-icon twitter-icon" size="lg"/></a></li>
                      <li onClick={setClipboard}><FontAwesomeIcon icon={faLink} className="mx-3 sharesheet-icon link-icon" size="lg"/></li>
                    </ul>
                  </div>
              </div>
            <div className="sep-line px-6 pt-8 pb-8"><div className="sep-line-inner"></div></div>
		      </section>
          <section className="disqus-comments">
            <div className="px-8">
              <Disqus
                identifier={uniqueID}
                title={title}
                url={`${articleUrl}`}
              />
            </div>
          </section>
          <section className="related-articles">
            <h2 className="p-4 font-bold text-2xl text-center">More From Credit 101</h2>
            <div className="flex flex-wrap overflow-hidden">
              <LatestPosts></LatestPosts>
            </div>
          </section>
				</div>
			</div>
    </StaticLayout>
  )
}

export default BlogPostLayout
